// Generated by Framer (a2c2d6f)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFonts, Image, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";
import Ticker from "https://framerusercontent.com/modules/B2xAlJLcN0gOnt11mSPw/nFAy8p4fOASsyhPbo192/Ticker.js";
const TickerFonts = getFonts(Ticker);

const cycleOrder = ["TchKVtxJ4", "zaok2B0wT"];

const variantClassNames = {TchKVtxJ4: "framer-v-1c2kala", zaok2B0wT: "framer-v-anem4x"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {"Variant 1": "TchKVtxJ4", Tablet: "zaok2B0wT"};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

const transformTemplate = (_, t) => `perspective(1200px) ${t}`

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; image?: {src: string; srcSet?: string} }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "TchKVtxJ4", image: wNkoEWnZa, ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "TchKVtxJ4", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-KrXU8", classNames)} style={{display: "contents"}}>
<motion.div {...restProps} className={cx("framer-1c2kala", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"TchKVtxJ4"} ref={ref} style={{backgroundColor: "rgb(255, 255, 255)", ...style}} transition={transition} {...addPropertyOverrides({zaok2B0wT: {"data-framer-name": "Tablet"}}, baseVariant, gestureVariant)}><motion.div className={"framer-1ws83n0-container"} data-framer-name={"Project Image Ticker"} layoutDependency={layoutDependency} layoutId={"gH5SP2Izu-container"} name={"Project Image Ticker"} style={{opacity: 0.85}} transformTemplate={transformTemplate} transition={transition}><Ticker alignment={"center"} direction={"left"} fadeOptions={{fadeAlpha: 0.4, fadeContent: true, fadeInset: 0, fadeWidth: 51, overflow: false}} gap={10} height={"100%"} hoverFactor={0.3} id={"gH5SP2Izu"} layoutId={"gH5SP2Izu"} name={"Project Image Ticker"} padding={0} paddingBottom={50} paddingLeft={10} paddingPerSide={false} paddingRight={10} paddingTop={50} sizingOptions={{heightType: true, widthType: true}} slots={[<Image background={{alt: "", fit: "fill", sizes: "862px", ...toResponsiveImage(wNkoEWnZa)}} className={"framer-1tfg2qv"} layoutDependency={layoutDependency} layoutId={"iBfhl34SG"} transition={transition}/>]} speed={60} style={{height: "100%", width: "100%"}} width={"100%"}/></motion.div></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-KrXU8 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-KrXU8 .framer-193qv4u { display: block; }", ".framer-KrXU8 .framer-1c2kala { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; width: 2774px; }", ".framer-KrXU8 .framer-1ws83n0-container { flex: none; height: 367px; position: relative; width: 100%; z-index: 1; }", ".framer-KrXU8 .framer-1tfg2qv { height: 555px; position: relative; width: 862px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-KrXU8 .framer-1c2kala { gap: 0px; } .framer-KrXU8 .framer-1c2kala > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-KrXU8 .framer-1c2kala > :first-child { margin-top: 0px; } .framer-KrXU8 .framer-1c2kala > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 367
 * @framerIntrinsicWidth 2774
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]},"zaok2B0wT":{"layout":["fixed","auto"]}}}
 * @framerVariables {"wNkoEWnZa":"image"}
 */
const FramerzjutHI7hQ: React.ComponentType<Props> = withCSS(Component, css, "framer-KrXU8") as typeof Component;
export default FramerzjutHI7hQ;

FramerzjutHI7hQ.displayName = "Ticker Comp.";

FramerzjutHI7hQ.defaultProps = {height: 367, width: 2774};

addPropertyControls(FramerzjutHI7hQ, {variant: {options: ["TchKVtxJ4", "zaok2B0wT"], optionTitles: ["Variant 1", "Tablet"], title: "Variant", type: ControlType.Enum}, wNkoEWnZa: {title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerzjutHI7hQ, [...TickerFonts])